import React from 'react';

function About() {
    return (
        <div>
            <h1>About Us</h1>
            {/* Content for About Us page */}
        </div>
    );
}

export default About;
