import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
    const routeLocation = useLocation();
    const [isHidden, setIsHidden] = React.useState(true);
    useEffect(() => {
        // Burger menus
        document.addEventListener('DOMContentLoaded', function () {
            // open
            const burger = document.querySelectorAll('.navbar-burger');
            const menu = document.querySelectorAll('.navbar-menu');

            if (burger.length && menu.length) {
                for (var i = 0; i < burger.length; i++) {
                    burger[i].addEventListener('click', function () {
                        for (var j = 0; j < menu.length; j++) {
                            menu[j].classList.toggle('hidden');
                        }
                    });
                }
            }

            // close
            const close = document.querySelectorAll('.navbar-close');
            const backdrop = document.querySelectorAll('.navbar-backdrop');

            if (close.length) {
                for (var i = 0; i < close.length; i++) {
                    close[i].addEventListener('click', function () {
                        for (var j = 0; j < menu.length; j++) {
                            menu[j].classList.toggle('hidden');
                        }
                    });
                }
            }

            if (backdrop.length) {
                for (var i = 0; i < backdrop.length; i++) {
                    backdrop[i].addEventListener('click', function () {
                        for (var j = 0; j < menu.length; j++) {
                            menu[j].classList.toggle('hidden');
                        }
                    });
                }
            }
        });
    }, []);
    return (
        <div>
            <nav className="bg-cover bg-center custom-bg-image h-full w-full relative px-4 py-4  flex justify-between items-center ">
                <a className="pl-6 text-3xl font-bold leading-none" href="#">
                    <img className="h-12" src="/logoArchi_256x256CropNoText.png" alt="logo" />
                </a>
                <div className="lg:hidden font-robotoSlab text-m flex justify-center items-center p-2 shadow-text">
                    <span>We shape our buildings; <br></br>thereafter, they shape us.</span>
                    <span></span>
                </div>
                {/* <div className="lg:hidden font-handwriting text-m">
                    <span>We shape our buildings; thereafter, they shape us.</span>
                    <span>thereafter, they shape us.</span>
                </div> */}
                <div className="lg:hidden">
                    <button className="navbar-burger flex items-center text-blue-600 p-3" onClick={() => setIsHidden(!isHidden)}>
                        <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
                <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
                    <li><a className={`text-sm ${routeLocation.pathname === '/' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:text-gray-500`} href="/">Home</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    <li><a className={`text-sm ${routeLocation.pathname === '/about' ? 'text-blue-600 font-bold' : 'text-gray-400'}`} href="/about">About Us</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    <li><a className={`text-sm ${routeLocation.pathname === '/portfolio' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:text-gray-500`} href="/portfolio">Portfolio</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    <li><a className={`text-sm ${routeLocation.pathname === '/contact' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:text-gray-500`} href="/contact">Contact</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                </ul>
                <a className="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold  rounded-xl transition duration-200" href="#">Sign In</a>
                <a className="hidden lg:inline-block py-2 px-6 bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold rounded-xl transition duration-200" href="#">Sign up</a>
            </nav>
            <div className={`navbar-menu relative z-50 ${isHidden ? 'hidden' : 'block'}`}>
                <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
                    <div className="flex items-center mb-8">
                        <a className="mr-auto text-3xl font-bold leading-none" href="#">
                            <img className="h-12" src="/logoArchi_256x256CropNoText.png" alt="logo" />
                        </a>
                        <button className="navbar-close" onClick={() => setIsHidden(!isHidden)}>
                            <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div>
                        <ul>
                            <li className="mb-1">
                                <a className={`block p-4 text-sm font-semibold ${routeLocation.pathname === '/' ? 'text-blue-600 font-bold' : 'text-gray-400'} text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded`} href="/">Home</a>
                            </li>
                            <li className="mb-1">
                                <a className={`block p-4 text-sm font-semibold ${routeLocation.pathname === '/about' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:bg-blue-50 hover:text-blue-600 rounded`} href="/about">About Us</a>
                            </li>
                            <li className="mb-1">
                                <a className={`block p-4 text-sm font-semibold ${routeLocation.pathname === '/portfolo' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:bg-blue-50 hover:text-blue-600 rounded`} href="/portfolo">Portfolio</a>
                            </li>
                            <li className="mb-1">
                                <a className={`block p-4 text-sm font-semibold ${routeLocation.pathname === '/contact' ? 'text-blue-600 font-bold' : 'text-gray-400'} hover:bg-blue-50 hover:text-blue-600 rounded`} href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-auto">
                        <div className="pt-6">
                            <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-xl" href="#">Sign in</a>
                            <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700  rounded-xl" href="#">Sign Up</a>
                        </div>
                        <p className="my-4 text-xs text-center text-gray-400">
                            <span>Copyright © 2021</span>
                        </p>
                    </div>
                </nav>
            </div>
        </div>
        // <nav>
        //     <ul>
        //         <li><Link to="/">Home</Link></li>
        //         <li><Link to="/portfolio">Portfolio</Link></li>
        //         <li><Link to="/about">About</Link></li>
        //     </ul>
        // </nav>
    );
}

export default Navigation;