import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './About';
import Portfolio from './Portfolio';
import Home from './Home';
import Contact from './Contact';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

import './output.css';

function App() {
  return (
    <Router>
      <Navigation></Navigation>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
