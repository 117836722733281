import React from 'react';

const Home = () => {
    return (
        <div className="container mx-auto p-4 text-blueGray-600 text-sm lg:text-lg">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 p-4">
                    <span>&nbsp;&nbsp;&nbsp;ArchiDesign studio, nestled in the heart of the picturesque city of Ohrid, Macedonia. With over a decade of experience, our intimate team of experts takes pride in crafting architectural masterpieces that stand the test of time. Specializing in the design of innovative buildings, cozy homes, and stunning contemporary interiors, we blend creativity with functionality to create spaces that are both aesthetically pleasing and highly practical. </span>
                    <span className='hidden lg:block'>Our commitment to excellence and attention to detail is evident in every project we undertake. We believe in a collaborative approach, working closely with our clients to turn their visions into reality. Whether you're looking to build your dream home or transform your space with a modern interior, our studio is dedicated to delivering designs that are tailored to your unique style and needs.</span>
                </div>
                <div className="md:w-1/2 p-4">
                    {/* <img style={{ maxWidth: '400px' }} src="proj1.jpg" alt="Descriptive Image Alt" className="max-w-full h-auto" /> */}
                    {/* <img src="proj1.jpg" alt="Descriptive Image Alt" className="max-w-full h-auto border-2 border-gray-300 rounded-lg shadow-lg" /> */}
                    <img src="proj1.jpg" alt="Descriptive Image Alt" className="max-w-full h-auto border border-gray-200 rounded-sm shadow" />

                </div>

            </div>
            <div>
                <div className='lg:hidden'>
                    <span className="lg:hidden xl:hidden">&nbsp;&nbsp;&nbsp;Our commitment to excellence and attention to detail is evident in every project we undertake. We believe in a collaborative approach, working closely with our clients to turn their visions into reality. Whether you're looking to build your dream home or transform your space with a modern interior, our studio is dedicated to delivering designs that are tailored to your unique style and needs.</span>
                </div>
                {/* <div>
                    <span>In the beautiful setting of Ohrid, our designs not only reflect the rich cultural heritage of the region but also incorporate modern trends and sustainable practices. Join us on a journey to transform your architectural dreams into enduring realities.</span>
                </div> */}
            </div>
        </div>
    );
};

export default Home;


